.modalFixed {
  position: fixed;
  max-width: 50rem;
  min-width: 30rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
  height: auto;
  background-color: white;
  z-index: 999;
}

.modalContainer {
  position: relative;
}

.modalHeader {
  background-color: #c4504b;
  width: 100%;
  height: 6rem;
  padding: 1.7rem 6rem 0 2rem;
}

.modalBody {
  padding: 2rem;
}

.modalTitle {
  font-family: 'Montserrat', sans-serif;
  font-size: 2.4rem;
  color: white;
  font-weight: 600;
}

.modalExitContainer {
  width: 6rem;
  height: 6rem;
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 2rem;
  padding-bottom: 2.2rem;
}

.modalExit {
  position: absolute;
  margin-right: 1rem;
  height: 2.2rem;
}

.modalMessage {
  font-size: 2.2rem;
  text-align: center;
  margin-bottom: 1.5rem;
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.modalAlert {
  height: 5rem;
}

.modalToilet {
  height: 8.4rem;
}

.modalCheckmark {
  height: 5rem;
}

.modalCheckmark,
.modalToilet,
.modalAlert {
  display: block;
  margin: 0.5rem auto 2rem;
}
