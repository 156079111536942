.navBarContainer {
  position: relative;
  z-index: 997;
  height: 8.5rem;
  border-bottom: solid #919191 1px;
}

.navbarContainer {
  z-index: 996;
  background: #f4f4f4;
  width: 100%;
  height: 8.5rem;
}

.navFixed {
  position: fixed;
}

.navScroll {
  position: absolute;
}

.navBarContainerLanding {
  position: relative;
  z-index: 996;
  height: 8.5rem;
  display: flex;
  justify-content: space-between;
}

.logoHome {
  position: absolute;
  right: calc(50vw - 5.7rem);
  top: 3.1rem;
}

.hamburgerContainer {
  height: 100%;
  padding: 2.8rem 3rem 2.7rem 3rem;
  margin-left: auto;
  right: 0;
  top: 0;
  position: absolute;
  z-index: 999;
}

.navTitle {
  height: 2.7rem;
  display: inline-block;
}

.landingSearch {
  display: inline-block;
  text-align: center;
}

.navSearchInput[type='text'] {
  display: inline-block;
  border: none;
  background-color: #c4504b;
  border-radius: 0;
  margin-top: 0;
  padding-left: 4rem;
  height: 8.4rem;
  position: absolute;
  width: 100%;
  color: #fff;
  padding-right: 7.3rem;
}

.navSearchInput[type='text']::placeholder {
  color: #fff;
}

.navSearchForm {
  position: relative;
}

.navSearchToggle {
  margin: 2.5rem;
  height: 3.3rem;
}

.searchButtonIcon {
  margin: 2.5rem 2rem;
  height: 3.3rem;
  width: 3.3rem;
}

.navSearchButton[type='submit'] {
  margin-top: 0;
  min-width: 3.3rem;
  height: 8.4rem;
  border-radius: 0;
  display: inline-block;
  position: absolute;
  right: 0;
}

.navSearchInput:focus {
  outline: none;
}

.searchDivider {
  height: 5rem;
  width: 0.1rem;
  background-color: #f4f4f4;
  position: absolute;
  right: 7.3rem;
  top: 1.7rem;
}

.navName {
  font-weight: 700;
  font-size: 2.3rem;
  line-height: 3.2rem;
  display: inline-block;
  letter-spacing: -0.1rem;
  margin-left: 0.7rem;
  font-style: none;
  color: black;
  padding-right: 1rem;
}

.navSearch {
  width: calc(100% - 9rem);
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: searchSlideOut;
  animation-name: searchSlideOut;
  position: absolute;
  left: calc(-100% + 9rem);
}

.showSearch {
  position: absolute;
}

@-webkit-keyframes searchSlideOut {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(7.8rem, 0, 0);
    transform: translate3d(7.8rem, 0, 0);
  }
  30% {
    -webkit-transform: translate3d(7.8rem, 0, 0);
    transform: translate3d(7.8rem, 0, 0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translate3d(calc(100% - 0rem), 0, 0);
    transform: translate3d(calc(100% - 0rem), 0, 0);
  }
}

@keyframes searchSlideOut {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(7.8rem, 0, 0);
    transform: translate3d(7.8rem, 0, 0);
  }
  30% {
    -webkit-transform: translate3d(7.8rem, 0, 0);
    transform: translate3d(7.8rem, 0, 0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translate3d(calc(100% - 0rem), 0, 0);
    transform: translate3d(calc(100% - 0rem), 0, 0);
  }
}

@-webkit-keyframes searchSlideIn {
  0% {
    -webkit-transform: translate3d(calc(100% - 0rem), 0, 0);
    transform: translate3d(calc(100% - 0rem), 0, 0);
  }
  70% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes searchSlideIn {
  0% {
    -webkit-transform: translate3d(calc(100% - 0rem), 0, 0);
    transform: translate3d(calc(100% - 0rem), 0, 0);
  }
  70% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.searchSlideIn {
  -webkit-animation-name: searchSlideIn;
  animation-name: searchSlideIn;
}

@media only screen and (max-width: 360px) {
  .searchButtonIcon {
    margin: 2.5rem 1.6rem;
  }
  .navSearch {
    width: calc(100% - 6.2rem);
  }
  .searchDivider {
    display: none;
  }
  .navSearchInput[type='text'] {
    padding-left: 3rem;
    padding-right: 6rem;
  }
  .hamburgerContainer {
    padding: 2.8rem 2rem 2.7rem 2rem;
  }
}
