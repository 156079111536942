.cardContainer {
  background-color: white;
  box-shadow: 1px 1px 6px 0 #cccccc;
  margin-bottom: 3rem;
  padding: 2rem 2rem 6rem;
  position: relative;
  overflow: hidden;
}

.cardTitleContainer {
  padding-right: 3rem;
}

.cardTitle {
  font-size: 2.8rem;
  color: #c4504b;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  margin-right: 0.8rem;
  text-decoration: none;
}

.packageVersion {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.8rem;
  font-weight: 300;
  display: inline-block;
  vertical-align: 0.1rem;
}

.packageDescription {
  font-size: 1.8rem;
  margin-top: 1.8rem;
  font-weight: 300;
}

.dotMenuButton {
  width: 4.4rem;
  height: 6rem;
  position: absolute;
  top: 0;
  right: 0;
}

.threeDot,
.dotMenuX {
  height: 2.2rem;
}

.dotMenuX {
  margin-right: 1rem;
}

.dotMenuOpen {
  background: #c4504b;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  min-width: 28rem;
  width: 50%;
  max-width: 40rem;
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  z-index: 2;
}

.slideMenuHidden {
  display: none;
}

.dotAnimationHidden {
  display: none;
}

@-webkit-keyframes dotAnimationOpen {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes dotAnimationOpen {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.dotAnimationOpen {
  -webkit-animation-name: dotAnimationOpen;
  animation-name: dotAnimationOpen;
}

@-webkit-keyframes dotAnimationClosed {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes dotAnimationClosed {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.dotAnimationClosed {
  -webkit-animation-name: dotAnimationClosed;
  animation-name: dotAnimationClosed;
}

.trashCanImage {
  height: 2.8rem;
  vertical-align: -0.6rem;
  margin-left: 0.1rem;
}

.three-dot-menu {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  padding: 1rem;
  align-items: flex-start;
}

.dotMenuItem:disabled {
  background-color: transparent;
  color: white;
}

.dotMenuItem {
  height: 4rem;
  padding-left: 0.5rem;
}

.dotMenuTitle {
  vertical-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
  color: white;
  margin-left: 1.4rem;
}

.favoriteStar {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  height: 2.6rem;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.8s ease-in-out;
  -moz-transition: 0.8s ease-in-out;
  -o-transition: 0.8s ease-in-out;
  transition: 0.8s ease-in-out;
  vertical-align: -0.5rem;
}

.starSpin {
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
}

.buttonImageContainer {
  height: 3rem;
  width: 3rem;
  display: inline-block;
}

.dotMenuForm {
  display: inline-block;
  height: 4rem;
  position: relative;
  padding-top: 0.5rem;
  background-color: white;
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 5rem;
}

.collectionOption {
  vertical-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
  color: #2c2c2c;
  margin-left: 0.5rem;
  width: 100%;
  height: 3rem;
  margin-top: 0;
  background: url('../../images/option-arrow.svg');
  background-repeat: no-repeat;
  background-size: 2rem;
  background-position: left;
  padding-left: 3.9rem;
  background-color: transparent;
  border: none;
  padding-right: 5rem;
}

.trashCanContainer {
  min-height: 2rem;
}

.dotSaveContainer[type='submit'],
.dotCheckmarkContainer {
  margin-top: 0;
  background-color: transparent;
  width: 2.6rem;
  min-width: 3rem;
  border: none;
  height: 3rem;
  display: inline-block;
  vertical-align: -0.4rem;
  position: absolute;
  right: 1rem;
  bottom: 0.2rem;
}

.dotSave {
  height: 2.3rem;
  margin-left: 0.5rem;
}

.npmContainer {
  height: 5.8rem;
  width: 9rem;
  position: absolute;
  bottom: 0;
  left: 0;
  padding-top: 1.8rem;
  padding-left: 2rem;
}

.npmLinkLogo {
  width: 5.8rem;
}

.scoreContainer {
  width: 5.4rem;
  height: 5.8rem;
  background: url('../../images/logo-ball-right-red.svg');
  background-size: 3.8rem;
  background-repeat: no-repeat;
  background-position: left top;
  position: absolute;
  right: 0;
  bottom: 0;
}

.packageScore {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-size: 1.6rem;
  color: white;
  position: absolute;
  top: 1.9rem;
  left: 0.5rem;
  width: 1.8rem;
  text-align: center;
}

.tooltipLeftContainer {
  width: 6rem;
  height: 5.8rem;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.npmTooltipLeft {
  width: 6rem;
  height: 5.8rem;
}

.tooltipRightContainer {
  width: 8rem;
  height: 5.8rem;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
}

.npmTooltipRight {
  width: 8rem;
  height: 5.8rem;
  position: absolute !important;
  left: 0;
  bottom: 0;
}
