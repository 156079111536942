.signupContainer {
  margin-top: 7.5rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  max-width: 80rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.signupHeading {
  font-size: 4rem;
  color: #2c2c2c;
  font-weight: 600;
}

.signupInstructions {
  font-family: Roboto;
  font-size: 2.2rem;
  color: #2c2c2c;
  font-weight: 300;
}

.signupForm input[type='text'],
.signupForm input[type='password'] {
  padding-left: 2rem;
  margin-right: 2rem;
  margin-top: 1.8rem;
}

.signupForm {
  margin-bottom: 6rem;
  margin-top: 2.7rem;
}

.signupSubmit[type='submit'] {
  margin-top: 3rem;
}

.signupSpinner {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.demoSignup {
  margin-top: 3rem;
}

@media only screen and (min-width: 768px) {
  .signupSubmit[type='submit'] {
    min-width: 20rem;
  }
}
