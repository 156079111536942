.metadataHeader {
  position: relative;
}

.packageDetails {
  font-size: 1.4rem;
  margin-top: 5.5rem;
  max-width: 110rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.markdownContainer pre {
  width: 100%;
  padding: 0;
  margin-top: 0;
  overflow-x: auto;
  overflow-y: hidden;
  font-size: 1.2rem;
  line-height: 2rem;
  background: #efefef;
  margin-bottom: 2.4rem;
  padding: 1.2rem;
  border-radius: 2px;
}

.markdownContainer blockquote {
  background: #efefef;
  padding: 1px 16px;
  margin-left: 0;
  margin-right: 0;
  border-left: #cecece solid 10px;
  border-radius: 3px;
}

.markdownContainer code {
  padding: 1rem;
  color: #333;
}
.markdownContainer li {
  list-style-type: initial;
  margin-bottom: 6px;
}

.markdownContainer a {
  color: #c4504b;
  font-size: 1em;
  font-weight: 600;
}

.markdownContainer ul {
  padding-left: 1em;
  margin-bottom: 1em;
  font-size: 1em;
  line-height: 1.3;
}

.markdownContainer h1,
.markdownContainer h2,
.markdownContainer h3,
.markdownContainer h4,
.markdownContainer p {
  margin: 0.8rem 0 1.6rem 0;
}

.markdownContainer hr {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.markdownContainer h2 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 8px;
}

.metadata {
  font-size: 1.4rem;
}

.markdownContainer {
  background-color: white;
  padding: 2rem 2rem 4rem;
  box-shadow: 1px 1px 6px 0 #cccccc;
}

.detailsTitle {
  font-size: 3.4rem;
  color: #c4504b;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  margin-right: 0.8rem;
  text-decoration: none;
}

.npmPackageLink {
  height: 5.8rem;
  width: 9rem;
  position: absolute;
  top: -1rem;
  right: 0;
  padding-top: 1.8rem;
  padding-left: 2rem;
}

.detailsTooltipLeft {
  width: 8rem;
  height: 5.8rem;
  position: absolute !important;
  left: 2rem;
  bottom: 0;
}

.detailsScoreContainer {
  position: absolute;
  top: 4rem;
  right: 7rem;
  padding-top: 0.8rem;
  padding-left: 2rem;
}

.scoreNumberContainer {
  width: 5.4rem;
  height: 5.8rem;
  background: url('../../images/logo-ball-right-red.svg');
  background-size: 3.8rem;
  background-repeat: no-repeat;
  background-position: left top;
  position: absolute;
  right: 0;
  bottom: 0;
}

.detailsScore {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-size: 1.6rem;
  color: white;
  position: absolute;
  top: 1.9rem;
  left: 0.5rem;
  width: 1.8rem;
  text-align: center;
  margin-top: 0 !important;
}

.metadataTitle,
.noReadme {
  font-weight: 500;
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
}

.metadataTitle,
.metadataContent,
.tagLink,
.noReadme {
  font-size: 1.8rem;
  font-family: 'Roboto', sans-serif;
}

.tagLink {
  margin-right: 1rem;
}

.metadataContent {
  margin: 0;
}

.readmeTitle {
  font-size: 2.4rem;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 1rem;
  margin-top: 3rem;
}

.tagLink,
.detailsLink {
  color: #2c2c2c;
  display: inline-block;
}

.scoreTitle {
  text-decoration: underline;
  margin-bottom: 0.5rem;
  font-weight: 600;
}
