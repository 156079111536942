.loginContainer {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  margin-bottom: 4rem;
  margin-top: 13rem;
  max-width: 80rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.loginForm {
  margin-top: 2.7rem;
}

.loginSubmit[type='submit'] {
  margin-top: 3rem;
}

.loginSpinner {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.titleContainer {
  position: relative;
}

.mrBuddy {
  position: absolute;
  height: 12.5rem;
  left: 0;
  bottom: 0.9rem;
}

.loginConfirmation {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.7);
}

.loginSuccess {
  text-align: center;
  font-size: 4rem;
  color: #2f2f2f;
  margin-top: 30rem;
}

.confirmationCheckmark {
  height: 12rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
}

@media only screen and (min-width: 768px) {
  .loginSubmit[type='submit'] {
    min-width: 20rem;
  }
}
