@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap);
* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
}

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: #f4f4f4;
}

h1,
h2,
h3,
h4 {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
}

.modalFixed {
  position: fixed;
  max-width: 50rem;
  min-width: 30rem;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
  height: auto;
  background-color: white;
  z-index: 999;
}

.modalContainer {
  position: relative;
}

.modalHeader {
  background-color: #c4504b;
  width: 100%;
  height: 6rem;
  padding: 1.7rem 6rem 0 2rem;
}

.modalBody {
  padding: 2rem;
}

.modalTitle {
  font-family: 'Montserrat', sans-serif;
  font-size: 2.4rem;
  color: white;
  font-weight: 600;
}

.modalExitContainer {
  width: 6rem;
  height: 6rem;
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 2rem;
  padding-bottom: 2.2rem;
}

.modalExit {
  position: absolute;
  margin-right: 1rem;
  height: 2.2rem;
}

.modalMessage {
  font-size: 2.2rem;
  text-align: center;
  margin-bottom: 1.5rem;
  width: 80%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.modalAlert {
  height: 5rem;
}

.modalToilet {
  height: 8.4rem;
}

.modalCheckmark {
  height: 5rem;
}

.modalCheckmark,
.modalToilet,
.modalAlert {
  display: block;
  margin: 0.5rem auto 2rem;
}

.landingContainer {
  margin: 0 auto;
  margin-top: 10rem;
  background-color: #f4f4f4;
}

.landingTitle {
  text-align: center;
  margin: 0 auto;
}

.landingInfoContainer {
  background-color: white;
  padding: 3rem 3rem 4rem;
  box-shadow: 1px 1px 6px 0 #cccccc;
  margin-bottom: 5rem;
  max-width: 80rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.landingIntro {
  max-width: 76.8rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.introContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6rem;
}

.landingSectionTitle {
  color: #2c2c2c;
  margin-bottom: 1.2rem;
  text-align: center;
  font-size: 2.8rem;
}

.logoMain {
  max-height: 14rem;
  margin-bottom: 0.8rem;
}

.landingSearchForm {
  max-width: 80rem;
}

.landingSearch {
  font-weight: 400;
  min-height: 6rem;
  width: 100%;
  margin-bottom: 8rem;
}

.underline {
  text-decoration: underline;
}

.description {
  color: #2c2c2c;
  margin: 0 auto;
  text-align: center;
  font-size: 1.8rem;
}

.landingSearchInput[type='text'] {
  padding-left: 6rem;
  background-repeat: no-repeat;
  background-size: 3.3rem;
  background-position: 1.4rem;
  background-image: url(/static/media/mag-glass-gray.2fca1a5b.svg);
}

.landingGettingStarted {
  margin: 0 auto;
  text-align: center;
  margin-bottom: 5rem;
}

.codeContainer {
  margin-top: 3rem;
  margin-bottom: 2rem;
  margin-right: 2rem;
  text-align: center;
}

.codeContainer code {
  padding: 1rem 2rem;
  border-radius: 5px;
  background-color: #565656;
  color: white;
}

.steps {
  margin: 0 auto;
  list-style: '\1F449';
  width: 80%;
}

.steps li {
  margin: 0.8rem;
  padding-left: 0.8rem;
  color: #2c2c2c;
  font-size: 1.8rem;
}

.steps a {
  color: #c33c3c;
  text-decoration: none;
  cursor: pointer;
  font-weight: 700;
  transition-duration: 200ms;
}

.steps a:hover {
  text-decoration: underline;
  transition-duration: 200ms;
}

.actionTitle {
  font-size: 1.7rem;
  color: #2c2c2c;
  margin-bottom: 0.5rem;
}

.actionContainer {
  position: relative;
  padding-left: 4rem;
  margin-bottom: 2.5rem;
  max-width: 55rem;
}

.checkmarkCircle {
  height: 2.8rem;
  position: absolute;
  left: 0;
  top: 0;
}

.actionDescription {
  font-size: 1.7rem;
  color: #2c2c2c;
  text-decoration: none;
}

.landingSectionActions {
  margin-top: 1.8rem;
  margin-bottom: 3rem;
  color: #2c2c2c;
  text-align: center;
  font-size: 2.8rem;
  text-decoration: underline;
}

.rocketImage {
  max-height: 14.4rem;
  display: block;
  margin: 3rem auto 2rem;
}

@media only screen and (min-width: 768px) {
  .landingSearchForm {
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .landingSearchButton.buttonSubmit {
    position: absolute;
    right: 0;
    border-radius: 0 0.5rem 0.5rem 0;
    min-width: 15rem;
    box-shadow: 1px 1px 8px 0 #cccccc;
  }

  .landingInfoContainer {
    padding-left: 4rem;
    padding-right: 4rem;
    padding-bottom: 3rem;
    max-width: 100rem;
    padding-top: 5rem;
  }

  .actionsFlex {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

.cardContainer {
  background-color: white;
  box-shadow: 1px 1px 6px 0 #cccccc;
  margin-bottom: 3rem;
  padding: 2rem 2rem 6rem;
  position: relative;
  overflow: hidden;
}

.cardTitleContainer {
  padding-right: 3rem;
}

.cardTitle {
  font-size: 2.8rem;
  color: #c4504b;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  margin-right: 0.8rem;
  text-decoration: none;
}

.packageVersion {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.8rem;
  font-weight: 300;
  display: inline-block;
  vertical-align: 0.1rem;
}

.packageDescription {
  font-size: 1.8rem;
  margin-top: 1.8rem;
  font-weight: 300;
}

.dotMenuButton {
  width: 4.4rem;
  height: 6rem;
  position: absolute;
  top: 0;
  right: 0;
}

.threeDot,
.dotMenuX {
  height: 2.2rem;
}

.dotMenuX {
  margin-right: 1rem;
}

.dotMenuOpen {
  background: #c4504b;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  min-width: 28rem;
  width: 50%;
  max-width: 40rem;
  -webkit-animation-duration: 0.6s;
  animation-duration: 0.6s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  z-index: 2;
}

.slideMenuHidden {
  display: none;
}

.dotAnimationHidden {
  display: none;
}

@-webkit-keyframes dotAnimationOpen {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes dotAnimationOpen {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.dotAnimationOpen {
  -webkit-animation-name: dotAnimationOpen;
  animation-name: dotAnimationOpen;
}

@-webkit-keyframes dotAnimationClosed {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes dotAnimationClosed {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.dotAnimationClosed {
  -webkit-animation-name: dotAnimationClosed;
  animation-name: dotAnimationClosed;
}

.trashCanImage {
  height: 2.8rem;
  vertical-align: -0.6rem;
  margin-left: 0.1rem;
}

.three-dot-menu {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  padding: 1rem;
  align-items: flex-start;
}

.dotMenuItem:disabled {
  background-color: transparent;
  color: white;
}

.dotMenuItem {
  height: 4rem;
  padding-left: 0.5rem;
}

.dotMenuTitle {
  vertical-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
  color: white;
  margin-left: 1.4rem;
}

.favoriteStar {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  height: 2.6rem;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: 0.8s ease-in-out;
  vertical-align: -0.5rem;
}

.starSpin {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.buttonImageContainer {
  height: 3rem;
  width: 3rem;
  display: inline-block;
}

.dotMenuForm {
  display: inline-block;
  height: 4rem;
  position: relative;
  padding-top: 0.5rem;
  background-color: white;
  width: 100%;
  padding-left: 0.5rem;
  padding-right: 5rem;
}

.collectionOption {
  vertical-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.8rem;
  font-weight: 400;
  color: #2c2c2c;
  margin-left: 0.5rem;
  width: 100%;
  height: 3rem;
  margin-top: 0;
  background: url(/static/media/option-arrow.4702782a.svg);
  background-repeat: no-repeat;
  background-size: 2rem;
  background-position: left;
  padding-left: 3.9rem;
  background-color: transparent;
  border: none;
  padding-right: 5rem;
}

.trashCanContainer {
  min-height: 2rem;
}

.dotSaveContainer[type='submit'],
.dotCheckmarkContainer {
  margin-top: 0;
  background-color: transparent;
  width: 2.6rem;
  min-width: 3rem;
  border: none;
  height: 3rem;
  display: inline-block;
  vertical-align: -0.4rem;
  position: absolute;
  right: 1rem;
  bottom: 0.2rem;
}

.dotSave {
  height: 2.3rem;
  margin-left: 0.5rem;
}

.npmContainer {
  height: 5.8rem;
  width: 9rem;
  position: absolute;
  bottom: 0;
  left: 0;
  padding-top: 1.8rem;
  padding-left: 2rem;
}

.npmLinkLogo {
  width: 5.8rem;
}

.scoreContainer {
  width: 5.4rem;
  height: 5.8rem;
  background: url(/static/media/logo-ball-right-red.9cb17edd.svg);
  background-size: 3.8rem;
  background-repeat: no-repeat;
  background-position: left top;
  position: absolute;
  right: 0;
  bottom: 0;
}

.packageScore {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-size: 1.6rem;
  color: white;
  position: absolute;
  top: 1.9rem;
  left: 0.5rem;
  width: 1.8rem;
  text-align: center;
}

.tooltipLeftContainer {
  width: 6rem;
  height: 5.8rem;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.npmTooltipLeft {
  width: 6rem;
  height: 5.8rem;
}

.tooltipRightContainer {
  width: 8rem;
  height: 5.8rem;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
}

.npmTooltipRight {
  width: 8rem;
  height: 5.8rem;
  position: absolute !important;
  left: 0;
  bottom: 0;
}

.collectionPageContainer {
  margin-top: 6rem;
}

.collectionTitleContainer {
  position: relative;
  height: 7rem;
  margin-bottom: 4rem;
}

.collectionTitle {
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
}

.collectionTitle,
.collectionTitle[type='text'] {
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 2.8rem;
  position: absolute;
  z-index: 1;
  left: 3.5rem;
  width: calc(100% - 11rem);
}

.collectionTitle[type='text'] {
  text-decoration: underline;
  border: none;
  background-color: transparent;
  margin-top: 0;
  height: auto;
  padding-left: 0;
  top: 3.5rem;
  font-weight: 400;
}

.collectionTitleBackground {
  height: 7rem;
  background-color: #c4504b;
  padding-left: 3rem;
  margin-left: 3.5rem;
  padding-right: 3rem;
  border-radius: 0 3.4rem 3.4rem 0;
  z-index: 0;
  position: relative;
}

.collectionTitleBackground::after {
  height: 9.8rem;
  bottom: 0rem;
  background-color: transparent;
  width: 9rem;
  content: '';
  background-image: url(/static/media/logo-ball-left-red.6d2c1b86.svg);
  position: absolute;
  background-repeat: no-repeat;
  left: -4.9rem;
}

.collectionNameValidation {
  position: absolute;
  bottom: -2.1rem;
  left: 3.2rem;
}

.collectionEditButton,
.collectionEditButton[type='submit'] {
  position: absolute;
  right: 0;
  height: 7rem;
  width: 7rem;
  z-index: 1;
  margin-top: 0;
  background-color: transparent;
  min-width: 0;
}

.editPencil,
.collectionTitleSave {
  height: 2.4rem;
  margin-right: 1rem;
}

.saveIcon {
  height: 2rem;
}

.collectionDelete {
  position: absolute;
  font-size: 1.6rem;
  font-weight: 400;
  bottom: -2.5rem;
  text-decoration: underline;
  right: 3rem;
  color: #2c2c2c;
}

.collectionTrash {
  height: 1.6rem;
  display: inline-block;
  vertical-align: -0.2rem;
  margin-right: 0.5rem;
}

@media only screen and (min-width: 768px) {
  .collectionPageContainer {
    max-width: 90rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .collectionPageContainer {
    margin-top: 8rem;
  }
}

.loginContainer {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  margin-bottom: 4rem;
  margin-top: 13rem;
  max-width: 80rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.loginForm {
  margin-top: 2.7rem;
}

.loginSubmit[type='submit'] {
  margin-top: 3rem;
}

.loginSpinner {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.titleContainer {
  position: relative;
}

.mrBuddy {
  position: absolute;
  height: 12.5rem;
  left: 0;
  bottom: 0.9rem;
}

.loginConfirmation {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.7);
}

.loginSuccess {
  text-align: center;
  font-size: 4rem;
  color: #2f2f2f;
  margin-top: 30rem;
}

.confirmationCheckmark {
  height: 12rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
}

@media only screen and (min-width: 768px) {
  .loginSubmit[type='submit'] {
    min-width: 20rem;
  }
}

.signupContainer {
  margin-top: 7.5rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  max-width: 80rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.signupHeading {
  font-size: 4rem;
  color: #2c2c2c;
  font-weight: 600;
}

.signupInstructions {
  font-family: Roboto;
  font-size: 2.2rem;
  color: #2c2c2c;
  font-weight: 300;
}

.signupForm input[type='text'],
.signupForm input[type='password'] {
  padding-left: 2rem;
  margin-right: 2rem;
  margin-top: 1.8rem;
}

.signupForm {
  margin-bottom: 6rem;
  margin-top: 2.7rem;
}

.signupSubmit[type='submit'] {
  margin-top: 3rem;
}

.signupSpinner {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.demoSignup {
  margin-top: 3rem;
}

@media only screen and (min-width: 768px) {
  .signupSubmit[type='submit'] {
    min-width: 20rem;
  }
}

.searchContainer {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  max-width: 90rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.searchTitle {
  margin-top: 7.5rem;
  max-width: 16rem;
  display: inline-block;
  margin-left: 2.5rem;
}

.searchInstructions {
  margin-bottom: 3rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.spacemanSearch {
  height: 10.5rem;
  display: inline-block;
  vertical-align: -0.5rem;
}

.pageInstructions.searchInstructions {
  margin-top: 2rem;
  margin-bottom: 4rem;
}

@media only screen and (min-width: 768px) {
  .searchTitleContainer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    text-align: right;
  }

  .pageInstructions.searchInstructions {
    text-align: center;
  }

  .searchTitle {
    margin-left: 0;
  }

  .spacemanSearch {
    margin-left: 1.5rem;
  }
}

.notFoundBanner {
  text-align: center;
  margin-top: 7.5rem;
}

.notFoundImage {
  height: 27rem;
}

.notFoundImageContainer {
  margin-top: 3rem;
  text-align: center;
  margin-bottom: 6rem;
}

.navMenu {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  max-width: 37.5rem;
  width: 100%;
  background-color: #c4504b;
  justify-content: space-evenly;
  padding: 12rem 7.2rem 6rem 0;
  z-index: 998;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  align-items: flex-end;
}

.menuScroll {
  height: auto;
}

.menuLink,
.collectionLink {
  font-size: 3.4rem;
  font-weight: 500;
  color: white;
  font-family: 'Montserrat', sans-serif;
}

.menuLink {
  height: 6rem;
  background-color: transparent;
  padding-left: 10rem;
  padding-top: 0.9rem;
  border-radius: 3.4rem 0 0 3.4rem;
  position: relative;
  margin-bottom: 3.5rem;
  text-decoration: underline;
}

.menuLink.active {
  color: #2c2c2c;
  background-color: white;
}

.menuLink.active::after {
  height: 8.2rem;
  bottom: 0;
  background-color: transparent;
  width: 9rem;
  content: '';
  background-image: url(/static/media/logo-ball-right-white.fada20b7.svg);
  position: absolute;
  right: -5.6rem;
  background-repeat: no-repeat;
  z-index: -1;
}

.collectionLink {
  margin: 0;
}

.collectionMenu {
  text-align: right;
}

.plusButton {
  height: 1.5rem;
  vertical-align: -0.1rem;
  margin-right: 0.6rem;
}

.createCollection,
.collectionMenuLink {
  font-family: 'Roboto', sans-serif;
  font-size: 2rem;
  font-weight: 200;
  text-decoration: underline;
  margin-top: 1.5rem;
  color: white;
  display: block;
  margin-left: auto;
}

.collectionMenuLink.active {
  font-weight: 800;
  letter-spacing: 0.1em;
}

.showMore {
  font-family: 'Roboto', sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  color: #2c2c2c;
  margin-top: 3rem;
  background-color: white;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
}

@media only screen and (max-width: 360px) {
  .navMenu {
    padding-right: 6rem;
  }
}

@media only screen and (min-width: 1025px) {
  .menuLink {
    text-decoration: none;
  }
  .menuLink:hover,
  .menuLink:focus {
    text-decoration: underline;
  }
  .createCollection,
  .collectionMenuLink {
    text-decoration: none;
  }
  .createCollection:hover,
  .collectionMenuLink:hover {
    text-decoration: underline;
  }
  .createCollection:focus,
  .collectionMenuLink:focus {
    text-decoration: underline;
  }
}

@media only screen and (max-height: 700px) {
  .menuLink {
    margin-bottom: 1.5rem;
  }
  .createCollection,
  .collectionMenuLink {
    margin-top: 0.7rem;
  }

  .showMore {
    margin-top: 2rem;
  }
  .navMenu {
    padding-top: 8rem;
    padding-bottom: 3rem;
  }
}

@media only screen and (max-height: 560px) {
  .navMenu {
    height: 60rem;
  }

  .menuScroll {
    height: auto;
  }

  .navFixed {
    position: absolute !important;
  }
}

.navBarContainer {
  position: relative;
  z-index: 997;
  height: 8.5rem;
  border-bottom: solid #919191 1px;
}

.navbarContainer {
  z-index: 996;
  background: #f4f4f4;
  width: 100%;
  height: 8.5rem;
}

.navFixed {
  position: fixed;
}

.navScroll {
  position: absolute;
}

.navBarContainerLanding {
  position: relative;
  z-index: 996;
  height: 8.5rem;
  display: flex;
  justify-content: space-between;
}

.logoHome {
  position: absolute;
  right: calc(50vw - 5.7rem);
  top: 3.1rem;
}

.hamburgerContainer {
  height: 100%;
  padding: 2.8rem 3rem 2.7rem 3rem;
  margin-left: auto;
  right: 0;
  top: 0;
  position: absolute;
  z-index: 999;
}

.navTitle {
  height: 2.7rem;
  display: inline-block;
}

.landingSearch {
  display: inline-block;
  text-align: center;
}

.navSearchInput[type='text'] {
  display: inline-block;
  border: none;
  background-color: #c4504b;
  border-radius: 0;
  margin-top: 0;
  padding-left: 4rem;
  height: 8.4rem;
  position: absolute;
  width: 100%;
  color: #fff;
  padding-right: 7.3rem;
}

.navSearchInput[type='text']::-webkit-input-placeholder {
  color: #fff;
}

.navSearchInput[type='text']::placeholder {
  color: #fff;
}

.navSearchForm {
  position: relative;
}

.navSearchToggle {
  margin: 2.5rem;
  height: 3.3rem;
}

.searchButtonIcon {
  margin: 2.5rem 2rem;
  height: 3.3rem;
  width: 3.3rem;
}

.navSearchButton[type='submit'] {
  margin-top: 0;
  min-width: 3.3rem;
  height: 8.4rem;
  border-radius: 0;
  display: inline-block;
  position: absolute;
  right: 0;
}

.navSearchInput:focus {
  outline: none;
}

.searchDivider {
  height: 5rem;
  width: 0.1rem;
  background-color: #f4f4f4;
  position: absolute;
  right: 7.3rem;
  top: 1.7rem;
}

.navName {
  font-weight: 700;
  font-size: 2.3rem;
  line-height: 3.2rem;
  display: inline-block;
  letter-spacing: -0.1rem;
  margin-left: 0.7rem;
  font-style: none;
  color: black;
  padding-right: 1rem;
}

.navSearch {
  width: calc(100% - 9rem);
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: searchSlideOut;
  animation-name: searchSlideOut;
  position: absolute;
  left: calc(-100% + 9rem);
}

.showSearch {
  position: absolute;
}

@-webkit-keyframes searchSlideOut {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(7.8rem, 0, 0);
    transform: translate3d(7.8rem, 0, 0);
  }
  30% {
    -webkit-transform: translate3d(7.8rem, 0, 0);
    transform: translate3d(7.8rem, 0, 0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translate3d(calc(100% - 0rem), 0, 0);
    transform: translate3d(calc(100% - 0rem), 0, 0);
  }
}

@keyframes searchSlideOut {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(7.8rem, 0, 0);
    transform: translate3d(7.8rem, 0, 0);
  }
  30% {
    -webkit-transform: translate3d(7.8rem, 0, 0);
    transform: translate3d(7.8rem, 0, 0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translate3d(calc(100% - 0rem), 0, 0);
    transform: translate3d(calc(100% - 0rem), 0, 0);
  }
}

@-webkit-keyframes searchSlideIn {
  0% {
    -webkit-transform: translate3d(calc(100% - 0rem), 0, 0);
    transform: translate3d(calc(100% - 0rem), 0, 0);
  }
  70% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes searchSlideIn {
  0% {
    -webkit-transform: translate3d(calc(100% - 0rem), 0, 0);
    transform: translate3d(calc(100% - 0rem), 0, 0);
  }
  70% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.searchSlideIn {
  -webkit-animation-name: searchSlideIn;
  animation-name: searchSlideIn;
}

@media only screen and (max-width: 360px) {
  .searchButtonIcon {
    margin: 2.5rem 1.6rem;
  }
  .navSearch {
    width: calc(100% - 6.2rem);
  }
  .searchDivider {
    display: none;
  }
  .navSearchInput[type='text'] {
    padding-left: 3rem;
    padding-right: 6rem;
  }
  .hamburgerContainer {
    padding: 2.8rem 2rem 2.7rem 2rem;
  }
}

.burgerButton {
  width: 3rem;
  height: 3rem;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  cursor: pointer;
  outline: none;
}

.falseBurger:hover span {
  box-shadow: 0.5px 0.5px 0 #2c2c2c;
}

.trueBurger:hover span {
  box-shadow: 0.5px 0.5px 0 white;
}

.falseBurger:focus span {
  box-shadow: 0.5px 0.5px 0 #2c2c2c;
}

.trueBurger:focus span {
  box-shadow: 0.5px 0.5px 0 white;
}

.burgerButton span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #2c2c2c;
  border-radius: 0.9rem;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
}

.burgerButton span:nth-child(1) {
  top: 0;
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

.burgerButton span:nth-child(2) {
  top: 1.2rem;
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

.burgerButton span:nth-child(3) {
  top: 2.4rem;
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

.trueBurger span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 0.4rem;
  width: 3.4rem;
  background: white;
}

.trueBurger span:nth-child(2) {
  width: 0%;
  opacity: 0;
  background: white;
}

.trueBurger span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 0.4rem;
  width: 3.4rem;
  background: white;
}

.slideMenuHidden {
  display: none;
}

@-webkit-keyframes slideMenuIn {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideMenuIn {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideMenuIn {
  -webkit-animation-name: slideMenuIn;
  animation-name: slideMenuIn;
}

@-webkit-keyframes slideMenuOut {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes slideMenuOut {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.slideMenuOut {
  -webkit-animation-name: slideMenuOut;
  animation-name: slideMenuOut;
}
.metadataHeader {
  position: relative;
}

.packageDetails {
  font-size: 1.4rem;
  margin-top: 5.5rem;
  max-width: 110rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.markdownContainer pre {
  width: 100%;
  padding: 0;
  margin-top: 0;
  overflow-x: auto;
  overflow-y: hidden;
  font-size: 1.2rem;
  line-height: 2rem;
  background: #efefef;
  margin-bottom: 2.4rem;
  padding: 1.2rem;
  border-radius: 2px;
}

.markdownContainer blockquote {
  background: #efefef;
  padding: 1px 16px;
  margin-left: 0;
  margin-right: 0;
  border-left: #cecece solid 10px;
  border-radius: 3px;
}

.markdownContainer code {
  padding: 1rem;
  color: #333;
}
.markdownContainer li {
  list-style-type: initial;
  margin-bottom: 6px;
}

.markdownContainer a {
  color: #c4504b;
  font-size: 1em;
  font-weight: 600;
}

.markdownContainer ul {
  padding-left: 1em;
  margin-bottom: 1em;
  font-size: 1em;
  line-height: 1.3;
}

.markdownContainer h1,
.markdownContainer h2,
.markdownContainer h3,
.markdownContainer h4,
.markdownContainer p {
  margin: 0.8rem 0 1.6rem 0;
}

.markdownContainer hr {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.markdownContainer h2 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 8px;
}

.metadata {
  font-size: 1.4rem;
}

.markdownContainer {
  background-color: white;
  padding: 2rem 2rem 4rem;
  box-shadow: 1px 1px 6px 0 #cccccc;
}

.detailsTitle {
  font-size: 3.4rem;
  color: #c4504b;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  margin-right: 0.8rem;
  text-decoration: none;
}

.npmPackageLink {
  height: 5.8rem;
  width: 9rem;
  position: absolute;
  top: -1rem;
  right: 0;
  padding-top: 1.8rem;
  padding-left: 2rem;
}

.detailsTooltipLeft {
  width: 8rem;
  height: 5.8rem;
  position: absolute !important;
  left: 2rem;
  bottom: 0;
}

.detailsScoreContainer {
  position: absolute;
  top: 4rem;
  right: 7rem;
  padding-top: 0.8rem;
  padding-left: 2rem;
}

.scoreNumberContainer {
  width: 5.4rem;
  height: 5.8rem;
  background: url(/static/media/logo-ball-right-red.9cb17edd.svg);
  background-size: 3.8rem;
  background-repeat: no-repeat;
  background-position: left top;
  position: absolute;
  right: 0;
  bottom: 0;
}

.detailsScore {
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-size: 1.6rem;
  color: white;
  position: absolute;
  top: 1.9rem;
  left: 0.5rem;
  width: 1.8rem;
  text-align: center;
  margin-top: 0 !important;
}

.metadataTitle,
.noReadme {
  font-weight: 500;
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
}

.metadataTitle,
.metadataContent,
.tagLink,
.noReadme {
  font-size: 1.8rem;
  font-family: 'Roboto', sans-serif;
}

.tagLink {
  margin-right: 1rem;
}

.metadataContent {
  margin: 0;
}

.readmeTitle {
  font-size: 2.4rem;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 1rem;
  margin-top: 3rem;
}

.tagLink,
.detailsLink {
  color: #2c2c2c;
  display: inline-block;
}

.scoreTitle {
  text-decoration: underline;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.footer {
  width: 100%;
  text-align: center;
  margin: 0;
  height: auto;
  position: absolute;
  bottom: 0;
}

.socialImage {
  width: 4.5rem;
}

.socialContainer {
  display: flex;
  justify-content: space-evenly;
  padding-top: 3rem;
  max-width: 100rem;
  margin: 0 auto;
}

.copyright {
  margin-top: 1em;
  padding-bottom: 1em;
  font-size: 1.4rem;
}

.footerSeparator {
  border-top: 1px solid #919191;
  margin-bottom: 0;
}

.scrollToTop {
  position: fixed;
  width: 4.5rem;
  bottom: 15rem;
  right: 0;
  align-items: center;
  height: 4.5rem;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  -webkit-animation: fadeIn 0.3s;
          animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;
  background-color: #c4504b;
  border-radius: 0;
}

.arrow {
  width: 3.5rem;
  height: 3.5rem;
}

.scrollToTop:hover {
  opacity: 1;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
.app {
  min-height: 100vh;
  position: relative;
  font-family: 'Roboto', sans-serif;
  font-size: 1.7rem;
  font-weight: 300;
  background-color: #f4f4f4;
}

p {
  margin: 0;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.appMain {
  padding: 8.5rem 2.5rem 18rem 2.5rem;
  margin: 0 auto;
}

button {
  background-color: transparent;
  cursor: pointer;
  border: none;
  padding: 0;
}

.buttonSubmit,
.modalButton {
  outline: none;
  font-family: 'Montserrat', sans-serif;
  margin-top: 1.8rem;
  font-size: 2rem;
  background-color: #c4504b;
  color: #fff;
  min-width: 100%;
  border-radius: 5px;
  height: 6rem;
  font-weight: 400;
  transition: all 0.1s ease-out 0.1s;
  -webkit-transition: all 0.1s ease-out 0.1s;
  -moz-transition: all 0.1s ease-out 0.1s;
  -o-transition: all 0.1s ease-out 0.1s;
}

.buttonSubmit:hover,
.buttonSubmit:focus,
.modalButton:hover,
.modalButton:focus {
  background-color: #a63b36;
  transition: 0.2s ease-in-out;
}

.standardInput:focus {
  border: 1px solid #2c2c2c;
  outline: none;
}

.standardInput,
.standardInput[type='text'] {
  box-shadow: 1px 1px 8px 0 #cccccc;
  border: none;
}

input,
input[type='text'],
select {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 5px;
  width: 100%;
  margin-top: 1.8rem;
  height: 6rem;
  background-color: white;
  box-shadow: none;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.8rem;
  padding-left: 2rem;
}

input::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #919191;
  opacity: 1;
  font-weight: 400;
}

input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #919191;
  opacity: 1;
  font-weight: 400;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #919191;
  opacity: 1;
  font-weight: 400;
}

input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #919191;
  opacity: 1;
  font-weight: 400;
}

.passwordInput {
  padding-right: 7rem;
}

.passwordContainer {
  position: relative;
}

.showPassword,
.hidePassword {
  background-image: url(/static/media/password-show.9f2c2604.svg);
  width: 6rem;
  height: 6rem;
  position: absolute;
  right: 0;
  top: 1.8rem;
  border-radius: 0.5rem;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: 2.2rem;
  background-position: center;
}

.showPassword {
  background-image: url(/static/media/password-show.9f2c2604.svg);
}

.hidePassword {
  background-image: url(/static/media/password-hide.65c2e326.svg);
}

button:disabled,
button[disabled] {
  background-color: #e8837f;
  color: #f4f4f4;
}

.textCenter {
  text-align: center;
}

.defaultMedium {
  font-weight: 500;
}

.underline {
  text-decoration: underline;
}

.inline {
  display: inline-block;
}

.pageTitle {
  font-size: 4rem;
  font-family: 'Montserrat', sans-serif;
  color: #2c2c2c;
  font-weight: 600;
}

.pageInstructions {
  font-family: 'Roboto', sans-serif;
  font-size: 2rem;
  font-weight: 300;
  color: #2c2c2c;
  margin-top: 2rem;
}

.validationWarning {
  color: #c4504b;
  font-size: 1.4rem;
  font-weight: 500;
  margin-top: 0.5rem;
}

.spinner {
  margin: 0 auto;
  margin-top: 30vh;
}

.linkBlack {
  color: black;
}

.tooltipLeft,
.tooltipRight {
  position: relative;
  display: inline-block;
}

.tooltipLeft .tooltiptext,
.tooltipRight .tooltiptext {
  visibility: hidden;
  background-color: #c4504b;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 0.5rem 0;
  position: absolute;
  z-index: 1;
  top: 50%;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  color: white;
}

.tooltipLeft .tooltiptext {
  right: 110%;
  width: 16rem;
}

.tooltipRight .tooltiptext {
  left: 110%;
  width: 19rem;
}

.tooltipRight .tooltiptext::after {
  right: 100%;
  border-color: transparent #c4504b transparent transparent;
}

.tooltipLeft .tooltiptext::after {
  left: 100%;
  border-color: transparent transparent transparent #c4504b;
}

.tooltipLeft .tooltiptext::after,
.tooltipRight .tooltiptext::after {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
  border-width: 0.5rem;
  border-style: solid;
}

.tooltipLeft:hover .tooltiptext,
.tooltipRight:hover .tooltiptext {
  visibility: visible;
}

.emptyImage {
  max-height: 30rem;
  margin-top: 3rem;
}

@media only screen and (min-width: 768px) {
  .appMain {
    padding-right: 4rem;
    padding-left: 4rem;
  }
}

