.burgerButton {
  width: 3rem;
  height: 3rem;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  cursor: pointer;
  outline: none;
}

.falseBurger:hover span {
  box-shadow: 0.5px 0.5px 0 #2c2c2c;
}

.trueBurger:hover span {
  box-shadow: 0.5px 0.5px 0 white;
}

.falseBurger:focus span {
  box-shadow: 0.5px 0.5px 0 #2c2c2c;
}

.trueBurger:focus span {
  box-shadow: 0.5px 0.5px 0 white;
}

.burgerButton span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #2c2c2c;
  border-radius: 0.9rem;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.burgerButton span:nth-child(1) {
  top: 0;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.burgerButton span:nth-child(2) {
  top: 1.2rem;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.burgerButton span:nth-child(3) {
  top: 2.4rem;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.trueBurger span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  left: 0.4rem;
  width: 3.4rem;
  background: white;
}

.trueBurger span:nth-child(2) {
  width: 0%;
  opacity: 0;
  background: white;
}

.trueBurger span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 0.4rem;
  width: 3.4rem;
  background: white;
}

.slideMenuHidden {
  display: none;
}

@-webkit-keyframes slideMenuIn {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideMenuIn {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideMenuIn {
  -webkit-animation-name: slideMenuIn;
  animation-name: slideMenuIn;
}

@-webkit-keyframes slideMenuOut {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes slideMenuOut {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.slideMenuOut {
  -webkit-animation-name: slideMenuOut;
  animation-name: slideMenuOut;
}