.scrollToTop {
  position: fixed;
  width: 4.5rem;
  bottom: 15rem;
  right: 0;
  align-items: center;
  height: 4.5rem;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;
  background-color: #c4504b;
  border-radius: 0;
}

.arrow {
  width: 3.5rem;
  height: 3.5rem;
}

.scrollToTop:hover {
  opacity: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}