.searchContainer {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  max-width: 90rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.searchTitle {
  margin-top: 7.5rem;
  max-width: 16rem;
  display: inline-block;
  margin-left: 2.5rem;
}

.searchInstructions {
  margin-bottom: 3rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.spacemanSearch {
  height: 10.5rem;
  display: inline-block;
  vertical-align: -0.5rem;
}

.pageInstructions.searchInstructions {
  margin-top: 2rem;
  margin-bottom: 4rem;
}

@media only screen and (min-width: 768px) {
  .searchTitleContainer {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    text-align: right;
  }

  .pageInstructions.searchInstructions {
    text-align: center;
  }

  .searchTitle {
    margin-left: 0;
  }

  .spacemanSearch {
    margin-left: 1.5rem;
  }
}
