.navMenu {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  max-width: 37.5rem;
  width: 100%;
  background-color: #c4504b;
  justify-content: space-evenly;
  padding: 12rem 7.2rem 6rem 0;
  z-index: 998;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  align-items: flex-end;
}

.menuScroll {
  height: auto;
}

.menuLink,
.collectionLink {
  font-size: 3.4rem;
  font-weight: 500;
  color: white;
  font-family: 'Montserrat', sans-serif;
}

.menuLink {
  height: 6rem;
  background-color: transparent;
  padding-left: 10rem;
  padding-top: 0.9rem;
  border-radius: 3.4rem 0 0 3.4rem;
  position: relative;
  margin-bottom: 3.5rem;
  text-decoration: underline;
}

.menuLink.active {
  color: #2c2c2c;
  background-color: white;
}

.menuLink.active::after {
  height: 8.2rem;
  bottom: 0;
  background-color: transparent;
  width: 9rem;
  content: '';
  background-image: url('../../images/logo-ball-right-white.svg');
  position: absolute;
  right: -5.6rem;
  background-repeat: no-repeat;
  z-index: -1;
}

.collectionLink {
  margin: 0;
}

.collectionMenu {
  text-align: right;
}

.plusButton {
  height: 1.5rem;
  vertical-align: -0.1rem;
  margin-right: 0.6rem;
}

.createCollection,
.collectionMenuLink {
  font-family: 'Roboto', sans-serif;
  font-size: 2rem;
  font-weight: 200;
  text-decoration: underline;
  margin-top: 1.5rem;
  color: white;
  display: block;
  margin-left: auto;
}

.collectionMenuLink.active {
  font-weight: 800;
  letter-spacing: 0.1em;
}

.showMore {
  font-family: 'Roboto', sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  color: #2c2c2c;
  margin-top: 3rem;
  background-color: white;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
}

@media only screen and (max-width: 360px) {
  .navMenu {
    padding-right: 6rem;
  }
}

@media only screen and (min-width: 1025px) {
  .menuLink {
    text-decoration: none;
  }
  .menuLink:hover,
  .menuLink:focus {
    text-decoration: underline;
  }
  .createCollection,
  .collectionMenuLink {
    text-decoration: none;
  }
  .createCollection:hover,
  .collectionMenuLink:hover {
    text-decoration: underline;
  }
  .createCollection:focus,
  .collectionMenuLink:focus {
    text-decoration: underline;
  }
}

@media only screen and (max-height: 700px) {
  .menuLink {
    margin-bottom: 1.5rem;
  }
  .createCollection,
  .collectionMenuLink {
    margin-top: 0.7rem;
  }

  .showMore {
    margin-top: 2rem;
  }
  .navMenu {
    padding-top: 8rem;
    padding-bottom: 3rem;
  }
}

@media only screen and (max-height: 560px) {
  .navMenu {
    height: 60rem;
  }

  .menuScroll {
    height: auto;
  }

  .navFixed {
    position: absolute !important;
  }
}
