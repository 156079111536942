.app {
  min-height: 100vh;
  position: relative;
  font-family: 'Roboto', sans-serif;
  font-size: 1.7rem;
  font-weight: 300;
  background-color: #f4f4f4;
}

p {
  margin: 0;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.appMain {
  padding: 8.5rem 2.5rem 18rem 2.5rem;
  margin: 0 auto;
}

button {
  background-color: transparent;
  cursor: pointer;
  border: none;
  padding: 0;
}

.buttonSubmit,
.modalButton {
  outline: none;
  font-family: 'Montserrat', sans-serif;
  margin-top: 1.8rem;
  font-size: 2rem;
  background-color: #c4504b;
  color: #fff;
  min-width: 100%;
  border-radius: 5px;
  height: 6rem;
  font-weight: 400;
  transition: all 0.1s ease-out 0.1s;
  -webkit-transition: all 0.1s ease-out 0.1s;
  -moz-transition: all 0.1s ease-out 0.1s;
  -o-transition: all 0.1s ease-out 0.1s;
}

.buttonSubmit:hover,
.buttonSubmit:focus,
.modalButton:hover,
.modalButton:focus {
  background-color: #a63b36;
  -webkit-transition: 0.2s ease-in-out;
  -moz-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.standardInput:focus {
  border: 1px solid #2c2c2c;
  outline: none;
}

.standardInput,
.standardInput[type='text'] {
  box-shadow: 1px 1px 8px 0 #cccccc;
  border: none;
}

input,
input[type='text'],
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 5px;
  width: 100%;
  margin-top: 1.8rem;
  height: 6rem;
  background-color: white;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.8rem;
  padding-left: 2rem;
}

input::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #919191;
  opacity: 1;
  font-weight: 400;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #919191;
  opacity: 1;
  font-weight: 400;
}

input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #919191;
  opacity: 1;
  font-weight: 400;
}

.passwordInput {
  padding-right: 7rem;
}

.passwordContainer {
  position: relative;
}

.showPassword,
.hidePassword {
  background-image: url('./images/password-show.svg');
  width: 6rem;
  height: 6rem;
  position: absolute;
  right: 0;
  top: 1.8rem;
  border-radius: 0.5rem;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: 2.2rem;
  background-position: center;
}

.showPassword {
  background-image: url('./images/password-show.svg');
}

.hidePassword {
  background-image: url('./images/password-hide.svg');
}

button:disabled,
button[disabled] {
  background-color: #e8837f;
  color: #f4f4f4;
}

.textCenter {
  text-align: center;
}

.defaultMedium {
  font-weight: 500;
}

.underline {
  text-decoration: underline;
}

.inline {
  display: inline-block;
}

.pageTitle {
  font-size: 4rem;
  font-family: 'Montserrat', sans-serif;
  color: #2c2c2c;
  font-weight: 600;
}

.pageInstructions {
  font-family: 'Roboto', sans-serif;
  font-size: 2rem;
  font-weight: 300;
  color: #2c2c2c;
  margin-top: 2rem;
}

.validationWarning {
  color: #c4504b;
  font-size: 1.4rem;
  font-weight: 500;
  margin-top: 0.5rem;
}

.spinner {
  margin: 0 auto;
  margin-top: 30vh;
}

.linkBlack {
  color: black;
}

.tooltipLeft,
.tooltipRight {
  position: relative;
  display: inline-block;
}

.tooltipLeft .tooltiptext,
.tooltipRight .tooltiptext {
  visibility: hidden;
  background-color: #c4504b;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 0.5rem 0;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translate(0, -50%);
  color: white;
}

.tooltipLeft .tooltiptext {
  right: 110%;
  width: 16rem;
}

.tooltipRight .tooltiptext {
  left: 110%;
  width: 19rem;
}

.tooltipRight .tooltiptext::after {
  right: 100%;
  border-color: transparent #c4504b transparent transparent;
}

.tooltipLeft .tooltiptext::after {
  left: 100%;
  border-color: transparent transparent transparent #c4504b;
}

.tooltipLeft .tooltiptext::after,
.tooltipRight .tooltiptext::after {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -0.5rem;
  border-width: 0.5rem;
  border-style: solid;
}

.tooltipLeft:hover .tooltiptext,
.tooltipRight:hover .tooltiptext {
  visibility: visible;
}

.emptyImage {
  max-height: 30rem;
  margin-top: 3rem;
}

@media only screen and (min-width: 768px) {
  .appMain {
    padding-right: 4rem;
    padding-left: 4rem;
  }
}
