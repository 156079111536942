.landingContainer {
  margin: 0 auto;
  margin-top: 10rem;
  background-color: #f4f4f4;
}

.landingTitle {
  text-align: center;
  margin: 0 auto;
}

.landingInfoContainer {
  background-color: white;
  padding: 3rem 3rem 4rem;
  box-shadow: 1px 1px 6px 0 #cccccc;
  margin-bottom: 5rem;
  max-width: 80rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.landingIntro {
  max-width: 76.8rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.introContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 6rem;
}

.landingSectionTitle {
  color: #2c2c2c;
  margin-bottom: 1.2rem;
  text-align: center;
  font-size: 2.8rem;
}

.logoMain {
  max-height: 14rem;
  margin-bottom: 0.8rem;
}

.landingSearchForm {
  max-width: 80rem;
}

.landingSearch {
  font-weight: 400;
  min-height: 6rem;
  width: 100%;
  margin-bottom: 8rem;
}

.underline {
  text-decoration: underline;
}

.description {
  color: #2c2c2c;
  margin: 0 auto;
  text-align: center;
  font-size: 1.8rem;
}

.landingSearchInput[type='text'] {
  padding-left: 6rem;
  background-repeat: no-repeat;
  background-size: 3.3rem;
  background-position: 1.4rem;
  background-image: url('../../images/mag-glass-gray.svg');
}

.landingGettingStarted {
  margin: 0 auto;
  text-align: center;
  margin-bottom: 5rem;
}

.codeContainer {
  margin-top: 3rem;
  margin-bottom: 2rem;
  margin-right: 2rem;
  text-align: center;
}

.codeContainer code {
  padding: 1rem 2rem;
  border-radius: 5px;
  background-color: #565656;
  color: white;
}

.steps {
  margin: 0 auto;
  list-style: '\1F449';
  width: 80%;
}

.steps li {
  margin: 0.8rem;
  padding-left: 0.8rem;
  color: #2c2c2c;
  font-size: 1.8rem;
}

.steps a {
  color: #c33c3c;
  text-decoration: none;
  cursor: pointer;
  font-weight: 700;
  transition-duration: 200ms;
}

.steps a:hover {
  text-decoration: underline;
  transition-duration: 200ms;
}

.actionTitle {
  font-size: 1.7rem;
  color: #2c2c2c;
  margin-bottom: 0.5rem;
}

.actionContainer {
  position: relative;
  padding-left: 4rem;
  margin-bottom: 2.5rem;
  max-width: 55rem;
}

.checkmarkCircle {
  height: 2.8rem;
  position: absolute;
  left: 0;
  top: 0;
}

.actionDescription {
  font-size: 1.7rem;
  color: #2c2c2c;
  text-decoration: none;
}

.landingSectionActions {
  margin-top: 1.8rem;
  margin-bottom: 3rem;
  color: #2c2c2c;
  text-align: center;
  font-size: 2.8rem;
  text-decoration: underline;
}

.rocketImage {
  max-height: 14.4rem;
  display: block;
  margin: 3rem auto 2rem;
}

@media only screen and (min-width: 768px) {
  .landingSearchForm {
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .landingSearchButton.buttonSubmit {
    position: absolute;
    right: 0;
    border-radius: 0 0.5rem 0.5rem 0;
    min-width: 15rem;
    box-shadow: 1px 1px 8px 0 #cccccc;
  }

  .landingInfoContainer {
    padding-left: 4rem;
    padding-right: 4rem;
    padding-bottom: 3rem;
    max-width: 100rem;
    padding-top: 5rem;
  }

  .actionsFlex {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
